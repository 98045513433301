import {FC} from "react";
import './NavigationBar.scss'
import {Network, useNetworks} from "../../../hooks/useNetworks";
import {Image, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap'
import {getAuthenticatedUser} from "../../../utils/auth";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";


interface NavigationBarProps {
    onLogout: () => void
}

export const NavigationBar: FC<NavigationBarProps> = ({onLogout}) => {
    const authenticatedUser = getAuthenticatedUser();
    const networks = useNetworks();
    const history = useHistory();
    const {t} = useTranslation();

    const onNetworkSelected = (network: Network) => {
        history.push(`/networks/${network.id}/stats`);
    }

    return (
        <Navbar expand="lg">
            <LinkContainer to="/">
                <Navbar.Brand>
                    <img
                        src="https://witick.io/img/logo.4fbec13.svg"
                        className="filter-white"
                        height="25"
                        alt="Witick"
                        style={{
                            marginTop: '-0.3rem',
                            marginLeft: '1rem'
                        }}/>
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {networks.length > 0 ?
                        <NavDropdown title={t('networks')} id="basic-nav-dropdown">
                            {networks.map(network =>
                                <NavDropdown.Item key={network.id} onClick={() => onNetworkSelected(network)}>
                                    <Image
                                        src={`https://cdn2.witick.io/logos/${network.reference}.png`}
                                        width="30"
                                        alt={network.name}
                                        style={{marginRight: '1rem'}}/>
                                    {network.name}
                                </NavDropdown.Item>)}
                        </NavDropdown> : null}

                    <LinkContainer to="/users/search">
                        <Nav.Link disabled={false} >
                            {t('users')}
                        </Nav.Link>
                    </LinkContainer>

                </Nav>

                {
                    authenticatedUser ?
                        <Nav>
                            <NavDropdown title={authenticatedUser.email} id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={onLogout}>
                                    Déconnexion
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav> : null
                }

            </Navbar.Collapse>
        </Navbar>
    )
}
