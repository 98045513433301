import React from 'react';
import {LoginPage} from "./pages/LoginPage/LoginPage";
import {NavigationBar} from "./components/common/NavigationBar/NavigationBar";
import { QueryClient, QueryClientProvider } from "react-query";
import {useAuthenticatedUser} from "./hooks/useAuthenticatedUser";
import {Route} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {UserOverviewPage} from "./pages/UserOverviewPage/UserOverviewPage";
import {UserSearchPage} from "./pages/UserSearchPage/UserSearchPage";
import {NetworkStatisticsPage} from "./pages/NetworkStatisticsPage/NetworkStatisticsPage";
import {NetworkCatalogPage} from "./pages/NetworkCatalogPage/NetworkCatalogPage";
import {TicketTemplatePage} from "./pages/TicketTemplatePage/TicketTemplatePage";

function App() {
    const {authenticatedUser, setAuthenticatedUser} = useAuthenticatedUser();

    if (!authenticatedUser) {
        return <LoginPage setAuthenticatedUser={setAuthenticatedUser}/>
    }

    const queryClient = new QueryClient();

    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <div className="h-100">
                    <NavigationBar onLogout={() => setAuthenticatedUser(null)}/>

                    <Route path="/users/search" component={UserSearchPage}/>
                    <Route path="/users/:userId/overview" component={UserOverviewPage}/>
                    <Route path="/networks/:networkId/stats" component={NetworkStatisticsPage}/>
                    <Route path="/networks/:networkId/catalog" component={NetworkCatalogPage}/>
                    <Route path="/networks/:networkId/ticket-templates/:ticketTemplateId" component={TicketTemplatePage}/>
                    <Route exact path="/" component={UserSearchPage}/>
                </div>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default App;
